import React, { useEffect, useState } from 'react'
import ShopHero from '~/components/Shop/ShopHero'
import { Col, Row } from 'react-bootstrap'
import ShopAmbassadorInfo from '~/components/Shop/ShopAmbassadorInfo'
import ShopFilters from '~/components/Shop/ShopFilters'
import ShopSearch from '~/components/Shop/ShopSearch'
import ChordServices, { KIT_PROPERTY_NAME } from '~/api/chord-services'
import ShopProductCard from '~/components/Shop/ShopProductCard'
import useAllProducts from '~/hooks/graphql/queries/use-all-products'
import Search from '~/components/Search'

const ShopPage = () => {
  const contentfulProducts = useAllProducts()
  const [allProducts, setAllProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [activeFilter, setActiveFilter] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')

  const filterProducts = filteredSlug => {
    if (filteredSlug !== null && allProducts.length > 0) {
      // Search by product type
      let products = allProducts.filter(prod => prod.productType.slug === filteredSlug)
      if (products.length === 0) {
        // Search by collection
        products = allProducts.filter(prod => {
          if (prod.collection?.find(item => item.slug === filteredSlug) !== undefined) {
            return prod
          }
        })
      }
      setFilteredProducts(products)
    }
  }
  useEffect(() => {
    const url = new URL(window.location.href)
    url.searchParams.get('s') !== null && setSearchTerm(url.searchParams.get('s'))

    ChordServices.allProducts()
      .then(result => {
        const products = []
        result.data.products.map(chordProduct => {
          // Only regular products (non-kits products)
          if (chordProduct.product_properties.find(item => item.property_name === KIT_PROPERTY_NAME) === undefined) {
            // Check if the chord product has a sibling in Contentful
            contentfulProducts.map(contenfulProduct => {
              if (contenfulProduct.sku === chordProduct.master.sku) {
                products.push({
                  ...contenfulProduct,
                  ...{
                    isOnStock: chordProduct.total_on_hand > 0,
                    display_price: chordProduct.display_price
                  }
                })
              }
            })
          }
        })
        setAllProducts(products)
      })
      .catch(() => {
        console.error('error fetching products')
      })
  }, [])
  useEffect(() => {
    if (allProducts.length > 0) {
      if (searchTerm !== '') {
        filterProducts(searchTerm)
      } else {
        setFilteredProducts(allProducts)
      }
    }
  }, [allProducts, searchTerm])
  useEffect(() => {
    filterProducts(searchTerm)
    return () => {}
  }, [searchTerm])
  useEffect(() => {
    filterProducts(activeFilter?.slug)
    return () => {}
  }, [activeFilter])
  return (
    <>
      <ShopHero />
      <Row className="mt-5 mb-4">
        <Col>
          <h1 className="fs-72 font-opensans-light">Shop Commons</h1>
        </Col>
        <Col className="d-flex justify-content-end">
          <ShopAmbassadorInfo />
        </Col>
      </Row>
      <Row>
        <Col>
          <ShopFilters handleTypeFilter={setActiveFilter} />
        </Col>
        <Col>
          <Search handleTypeFilter={setSearchTerm} indices={[{ name: 'products', longDescription: 'Products' }]} />
        </Col>
      </Row>
      {activeFilter !== null && (
        <Row className="mt-6">
          <Col sm={8}>
            <h3 className="fs-24 font-opensans-semibold">{activeFilter.title}</h3>
            {activeFilter.description && (
              <div
                className="fs-20 font-opensans-regular"
                dangerouslySetInnerHTML={{ __html: activeFilter.description }}
              />
            )}
          </Col>
        </Row>
      )}
      {filteredProducts.length > 0 && (
        <Row>
          {filteredProducts.map(product => {
            return (
              <Col sm={4} key={product.id} className="mt-9">
                <ShopProductCard product={product} />
              </Col>
            )
          })}
        </Row>
      )}
    </>
  )
}

export default ShopPage

import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { FaSearch as SearchIcon } from 'react-icons/fa'

export default connectSearchBox(({ refine, currentRefinement, className, onFocus, hasFocus, handleFilter }) => (
  <form
    className={`d-flex justify-content-end align-items-center ${className}`}
    onSubmit={e => {
      e.preventDefault()
      handleFilter(e.target.elements.searchTerm.value)
      window.history.replaceState(null, null, `?s=${e.target.elements.searchTerm.value}`)
    }}
  >
    <input
      className={` ${hasFocus ? 'search-open' : 'search-closed'}`}
      type="text"
      placeholder="Search"
      aria-label="Search"
      onChange={e => refine(e.target.value)}
      value={currentRefinement}
      onFocus={onFocus}
      name="searchTerm"
    />
    <SearchIcon className="icon-search" />
  </form>
))

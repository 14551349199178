import React, { useEffect, useState } from 'react'
import useAllProductTypesCollectionsQuery from '~/hooks/graphql/queries/all-product-types-collections'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { Link } from 'gatsby'
import { navigate, useParams } from '@reach/router'

const ShopFilters = ({ handleTypeFilter }) => {
  const params = useParams()
  const { productTypes, collections } = useAllProductTypesCollectionsQuery()
  const [selectedFilter, setSelectedFilter] = useState(null)

  useEffect(() => {
    let filter = null
    if (params.productType !== undefined) {
      filter = getFilterType(params.productType)
    }
    if (params.purpose !== undefined) {
      filter = getFilterCollection(params.purpose)
    }
    setSelectedFilter(filter)
    return () => {}
  }, [params.productType, params.purpose])

  const getFilterType = slug => {
    if (slug !== null && productTypes.length > 0) {
      const filter = productTypes.find(prodType => prodType.slug === slug)
      if (filter !== undefined) {
        return { title: filter.name, description: filter.fullDescription, slug: filter.slug }
      }
    }
    return null
  }

  const getFilterCollection = slug => {
    if (slug !== null && collections.length > 0) {
      const filter = collections.find(collection => collection.slug === slug)
      if (filter !== undefined) {
        return { title: filter.title, description: null, slug: filter.slug }
      }
    }
    return null
  }

  useEffect(() => {
    handleTypeFilter(selectedFilter)
    return () => {}
  }, [selectedFilter])

  return (
    <div className="d-flex flex-row">
      <Dropdown className="shop-filter">
        <Dropdown.Toggle variant={null} id="dropdown-product-type">
          PRODUCT TYPE
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {productTypes.map(item => {
            return (
              <Dropdown.Item
                onClick={() => setSelectedFilter(getFilterType(item.slug))}
                className="text-uppercase text-blue-light fw-bold"
                key={item.id}
              >
                {item.name}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="shop-filter ms-3">
        <Dropdown.Toggle variant={null} id="dropdown-product-purpose">
          FOR HELP WITH
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {collections.map(item => {
            return (
              <Dropdown.Item
                onClick={() => setSelectedFilter(getFilterCollection(item.slug))}
                className="text-uppercase text-blue-light fw-bold"
                key={item.id}
              >
                {item.title}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default ShopFilters

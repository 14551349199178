import React from 'react'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { Router } from '@reach/router'
import ShopPage from '~/components/Shop/ShopPage'

const Shop = () => {
  return (
    <Layout>
      <Metadata title="Shop" />

      <Router basepath="/shop">
        <ShopPage path="/" />
        <ShopPage path="/purpose/:purpose" />
        <ShopPage path="/type/:productType" />
      </Router>
    </Layout>
  )
}

export default Shop

import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'

const ShopProductCard = ({ product }) => {
  const image = getImage(product.mainImage)
  return (
    <div className="d-flex flex-column c-pointer" onClick={() => navigate(`/products/${product.slug}`)}>
      <div className="mb-4">
        <GatsbyImage image={image} alt={product.mainImage.title} />
      </div>
      <h3 className="fs-20 font-sfpro fw-bold m-0">{product.name}</h3>
      <p className="font-opensans-regular my-2">{product.shortDescription}</p>
      <span className="fs-20 font-sfpro">{product.display_price}</span>
    </div>
  )
}

export default ShopProductCard

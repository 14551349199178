import { default as React } from 'react'
import { connectStateResults, Highlight, Hits, Index, Snippet } from 'react-instantsearch-dom'

const windowGlobal = typeof window !== 'undefined' && window

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div className="text-end">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : null
})

const PageHit = ({ hit }) => (
  <div>
    <div onClick={() => windowGlobal && windowGlobal.open(`/products/${hit.slug}`, '_self')}>
      <h4>
        <Highlight attribute="name" hit={hit} tagName="mark" />
      </h4>
    </div>
    <Snippet attribute="shortDescription" hit={hit} tagName="mark" />
  </div>
)

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="d-flex justify-content-end mt-1" hitComponent={PageHit} />
  </Index>
)

const SearchResult = ({ indices, show }) => (
  <div className={`search-results shadow ${show ? 'd-flex' : 'd-none'} d-flex flex-column`}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)

export default SearchResult

import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import useShopPage from '~/hooks/graphql/queries/use-shop-page'
import { getSrc } from 'gatsby-plugin-image'

const ShopHero = () => {
  const shopPage = useShopPage()
  const [heroData, setHeroData] = useState(null)

  useEffect(() => {
    {
      shopPage.blocks.map(block => {
        if (block.__typename === 'ContentfulImageWithOverlayText') {
          setHeroData(block)
        }
      })
    }

    return () => {
      setHeroData(null)
    }
  }, [])

  if (heroData === null) {
    return <></>
  }

  return (
    <div className="container-fluid overflow-hidden px-0" style={{ marginTop: '100px' }}>
      <div className="row">
        <div
          className="img-fluid"
          style={{
            backgroundColor: 'white',
            backgroundImage: `url('${heroData.image && getSrc(heroData.image)}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '80vh',
            maxHeight: '90vh'
          }}
        >
          <div
            className="col-12 col-md-6"
            style={{ paddingLeft: '3.5rem', paddingTop: '3.5rem', paddingRight: '3.5rem' }}
          >
            <h1 className="bannerImageTitle" style={{ color: heroData.fontColor }}>
              {heroData.title}
            </h1>
            <h2 className="bannerImageSubtitle text-uppercase" style={{ color: heroData.fontColor }}>
              {heroData.subheading}
            </h2>
            {heroData.buttonLink && heroData.buttonTitle && (
              <Link
                className="bannerImageCTA d-flex align-items-center justify-content-center w-100"
                to={heroData.buttonLink}
              >
                {heroData.buttonTitle}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopHero

import { graphql, useStaticQuery } from 'gatsby'

const useShopPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulNewPage(title: { eq: "Shop" }) {
        title
        blocks {
          ... on ContentfulImageWithOverlayText {
            __typename
            id
            fontColor
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            title
            subheading
            buttonTitle
            buttonLink
          }
        }
      }
    }
  `)

  return data.contentfulNewPage
}

export default useShopPage

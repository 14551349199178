import { graphql, useStaticQuery } from 'gatsby'
const useAllProductsQuery = () => {
  const products = []
  const result = useStaticQuery(graphql`
    query ShopPage {
      allContentfulProduct {
        nodes {
          name
          sku
          slug
          mainImage {
            title
            gatsbyImageData(layout: CONSTRAINED, quality: 100, placeholder: BLURRED, formats: [WEBP, AUTO], width: 610)
          }
          shortDescription
          description {
            raw
          }
          klaviyoListId
          id
          productType {
            slug
          }
          collection {
            slug
          }
        }
      }
    }
  `)

  result.allContentfulProduct.nodes.map(item => products.push(item))
  return products
}
export default useAllProductsQuery

import React from 'react'

const ShopAmbassadorInfo = () => {
  return (
    <div className="d-flex flex-row align-items-center">
      <div className="fs-24 font-sfpro">
        Shopping with: <strong>Dave Lin</strong>
      </div>
      <div>
        {/*<img src="https://picsum.photos/64" className="ms-3" alt="" style={{ height: '64px', width: '64px' }} />*/}
      </div>
    </div>
  )
}

export default ShopAmbassadorInfo
